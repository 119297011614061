import React, { Component } from 'react';
import { IExamContent } from './Exam';
import './ExamSummary.scss';
import shortEnglishHumanizer from 'src/helpers/shortEnglishHumanizer';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PreviousAttempts from './Card/Attempts/PreviousAttempts';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import { RouteComponentProps, withRouter } from 'src/hoc/withRouter';
import { Api, EventBus, Utility } from 'src/helpers/new';
import { Spinner } from 'src/components/Spinner';
import CourseContext from '../../CourseContext';
import { isEmpty } from 'lodash';
import ExtensionRepurchase from 'src/pages/Checkout/ExtensionRepurchase';
import WithOverFlowTooltip from 'src/helpers/withOverFlowTooltip';
import { setState } from 'src/helpers/localStorage';

interface IRouteProps {
    courseId: string;
    chapterId: string;
    lessonId: string;
    userPackageId?: string;
}
interface RepurchaseState {
    [key: string]: {
        price: string;
        purchaseType: string;
        userPackageId: string;
        _id: string;
        userCourseId: string;
    };
}

interface IProps {
    location: {
        state: {
            isResumeExam: boolean;
        };
    };
}
interface IState {
    current: IExamContent;
    lastAttempts: any[];
    isExamLoading: boolean;
    repurchasePackageId: string;
    course: any;
    isUserRestricted: boolean;
    certificateData: {
        url: string;
        fileName: string;
    };
    originalExamInfo: {
        questionsLength: number;
        passPct: number;
        timeLimit: number;
    };
}

type TProps = IProps & RouteComponentProps<IRouteProps>;
class ExamSummary extends Component<TProps, IState> {
    static contextType = CourseContext;
    context!: React.ContextType<typeof CourseContext>;

    state: IState = {
        current: {
            pausedAt: [],
            title: '',
            startedAt: [],
            questionSubsetCount: 0,
            questions: [],
            timeLimit: 0,
            _id: '',
            createdAt: '',
            examType: 'slide',
            remainingTime: 0,
            spentTime: 0,
        },
        course: {
            _id: '',
            attemptLimit: '',
            minimumCompletionTime: 0,
            attemptDelay: 0,
            examType: 'slide',
            courseTitle: '',
            aboutExam: '',
            lastChapterId: '',
            lastLessonId: '',
        },
        lastAttempts: [],
        isExamLoading: false,
        repurchasePackageId: '',
        isUserRestricted: false,
        certificateData: {
            url: '',
            fileName: '',
        },
        originalExamInfo: {
            questionsLength: 0,
            passPct: 0,
            timeLimit: 0,
        },
    };

    get isExamAvailable(): { isAvailable: boolean; message: string; isReview?: boolean } {
        const { examStatus } = this.context;
        if (examStatus) {
            const { available, message, inReview } = examStatus;
            return { isAvailable: available, message: message, isReview: inReview };
        }

        // Provide default values if examStatus is undefined
        return { isAvailable: false, message: 'No exam available', isReview: false };
    }

    get getFinalAttemptMessage(): { isFinalAttempt: boolean; message: string } {
        const { course } = this.state;

        if (
            (course.examAttempts ?? 0) + 1 === course.attemptLimit &&
            course.attemptLimit &&
            course.status !== 'EXAM_PASSED'
        ) {
            return {
                isFinalAttempt: true,
                message: `You have one attempt remaining. <br />
            If you fail you will be able to re-purchase the course and try again.`,
            };
        }
        return { isFinalAttempt: false, message: '' };
    }

    get getAttemptDisplayCount() {
        const { course } = this.state;
        if (course.attemptLimit) {
            return `${course.attemptLimit}  (${course.attemptLimit - (course.examAttempts ?? 0)} remaining)`;
        }
        return 'Unlimited';
    }

    componentDidMount(): void {
        if (this.props.location.state && this.props.location.state?.isResumeExam) {
            EventBus.dispatch('enter-exam-screen');
        }
        this.loadExamData();
        EventBus.dispatch('display-chat-icon', { enable: false, course: this.context.course });
        window.socket.on('maintenance-settings-updated', this.maintenanceEvent);
    }

    componentWillUnmount(): void {
        window.socket.off('maintenance-settings-updated', this.maintenanceEvent);
        EventBus.dispatch('show-exam-banner', { isResume: null });
    }

    loadExamData = async () => {
        this.setState({ isExamLoading: true });
        const { success, response } = await Api.call('GET', `/users/exam/${this.props.params.courseId}`);
        const { success: maintenanceSuccess, response: maintenanceRsp } = await Api.call(
            'GET',
            '/settings/maintenance',
        );

        if (success) {
            response.current = response.current ? { ...response.current } : null;
            if (response.userExamDetails) {
                if (this.context.unlockExam) {
                    this.context.unlockExam(response.userExamDetails);
                }
            }

            if (response.lastAttempts.length > 0) {
                if (!response.lastAttempts[0].isExamVerified) {
                    const examId = response.lastAttempts[0]._id;
                    EventBus.dispatch('confirmation-popup', {
                        title: 'Out of time',
                        body: 'You have run out of time for your last exam attempt. Your answers have been submitted',
                        confirm: {
                            text: 'Okay',
                            action: () => {},
                        },
                        cancel: null,
                    });
                    this.examVerified(examId);
                }
            }

            if (
                response.current &&
                this.getRemainingTime(response.current) !== 0 &&
                response.current?.spentTime <= response.current?.timeLimit
            ) {
                response.lastAttempts = [response.current].concat(response.lastAttempts ?? []);
            }

            if (maintenanceSuccess && maintenanceRsp?.startTime) {
                if (Utility.getMaintenanceDuration(maintenanceRsp?.startTime, 3)) {
                    this.setState({ isUserRestricted: true });
                }
            }

            this.setState({
                ...response,
                isExamLoading: false,
                course: response.mainCourse,
            });
        }
    };

    maintenanceEvent = async (data: any) => {
        if (!isEmpty(data)) {
            if (data?.maintenance?.startTime && data?.maintenance?.endTime) {
                if (Utility.getMaintenanceDuration(data?.maintenance?.startTime, 3)) {
                    this.setState({ isUserRestricted: true });
                } else {
                    this.setState({ isUserRestricted: false });
                }
            }
        }
    };

    examVerified = async (examId: string) => {
        await Api.call('PUT', `/users/exam/${examId}`, {
            isExamVerified: true,
        });
    };

    getRemainingTime(current: IExamContent): number {
        const time = current?.remainingTime && current?.remainingTime > 0 ? current?.remainingTime : 0;
        return time;
    }

    isStartExamBtnVisible = () => {
        if (this.state.course?.status === 'EXAM_PASSED' && !this.isExamAvailable.isReview) return false;

        return true;
    };

    startNewExam = () => {
        EventBus.dispatch('confirmation-popup', {
            title: 'Start Exam',
            body: 'By clicking the "Start Exam" button below you acknowledge that you read and understand the exam instructions and requirements.',
            confirm: {
                text: 'Start Exam',
                action: () => {
                    EventBus.dispatch('enter-exam-screen');
                },
            },
            cancel: {
                action: () => {},
            },
        });
    };

    onExamExit = () => {
        const { course } = this.state;
        if (course && course?.lastChapterId && course?.lastLessonId) {
            EventBus.dispatch('display-chat-icon', { enable: true, course: this.context.course });
            this.props.navigate(
                `/courses/${this.props.params.courseId}/chapters/${course.lastChapterId}/lessons/${course.lastLessonId}`,
                {
                    state: {
                        isToBypassBiosigOnComponentMount: true,
                    },
                },
            );
        }
    };

    validateBiosig = () => {
        EventBus.dispatch('require-auth', {
            stage: 'beforeExam',
            callback: () => {},
        });
    };

    enterInExam = () => {
        EventBus.dispatch('enter-exam-screen');
    };

    repurchaseCourse = () => {
        const repurchaseData: RepurchaseState = {};

        repurchaseData[this.context.course.userPackageId] = {
            price: this.context.course.repurchase,
            purchaseType: 'repurchase',
            userPackageId: this.context.course.userPackageId,
            _id: this.context.course.courseId,
            userCourseId: this.context.course._id,
        };

        const data = {
            ...Object.values(repurchaseData).filter((data: any) => {
                return true;
            }),
        };

        setState('cartPackageId', this.context.course.userPackageId);
        this.props.navigate(`/checkout`, { state: data });
    };

    displayWarningMessage = () => {
        let message = '',
            isDisplayWarning = false;

        const { course } = this.state;
        if (!this.isExamAvailable.isAvailable) {
            isDisplayWarning = true;
            message = this.isExamAvailable.message;
        } else if (this.getFinalAttemptMessage.isFinalAttempt) {
            isDisplayWarning = true;
            message = this.getFinalAttemptMessage.message;
        }

        if (this.isExamAvailable.isReview) {
            isDisplayWarning = false;
            message = '';
        }

        if (!isEmpty(course.attemptLimit) && course.examAttempts >= course.attemptLimit && course.status === 'FAILED') {
            isDisplayWarning = false;
            message = '';
        }

        if (!message.includes('<p>')) {
            message = '<p>' + message + '</p>';
        }

        if (isDisplayWarning) {
            return (
                <div className='attempt-info'>
                    <div className={`icon`}>
                        <i className='fa fa-circle-info' />
                    </div>
                    <div className='warn-message' dangerouslySetInnerHTML={{ __html: message }} />
                </div>
            );
        }
        return null;
    };

    renderRepurchaseBlock = () => {
        const { course } = this.state;
        if (
            course.status === 'FAILED' &&
            course.attemptLimit === (course.examAttempts ?? 0) &&
            this.context.course.offerConditions?.fail === 'repurchase'
        ) {
            return (
                <div className='display-repurchase-block'>
                    <div className='repurchase-container'>
                        <div className='repurchase-info'>
                            <h4>{`Don't worry.We've got you covered.`}</h4>
                            <p>
                                {`Sometimes things don't go as planned.But you've \n already done all the hard work,
                                so don't be thrown off.`}
                            </p>
                            <p>
                                {`To take a new exam and get back on track with your real estate license, you'll need to
                                purchase a new package.`}
                            </p>
                            <p>{`We've made it available at a reduced price for students who need to resit their exam.`}</p>
                        </div>
                        {this.context.course.repurchase && (
                            <div className='repurchase-block'>
                                <h5>
                                    <WithOverFlowTooltip>{course.courseTitle}</WithOverFlowTooltip>
                                </h5>
                                <p
                                    className='about-extension'
                                    dangerouslySetInnerHTML={{ __html: this.context.course?.aboutExtension }}
                                />
                                <div className='repurchase-button-wrapper'>
                                    <div className='price-wrapper'>
                                        <h4>${this.context.course.repurchase}</h4>
                                        <h5>${this.context.course.originalPrice}</h5>
                                    </div>

                                    <Button
                                        className={`bp ${!this.isExamAvailable.isAvailable ? 'button--disabled' : ''}`}
                                        onClick={this.repurchaseCourse}
                                    >
                                        Repurchase
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return null;
    };
    render() {
        if (this.state.isExamLoading) return <Spinner />;

        const { current, lastAttempts, course, originalExamInfo } = this.state;
        const { bioSight } = this.context.course.proctoringSettings;

        return (
            <div className='exam-summary'>
                <span className='exam-summary__header'>
                    <h3>Final Exam | {course?.courseTitle}</h3>
                    {bioSight === 'biosight' && (
                        <OverlayTrigger
                            placement='bottom'
                            trigger={['hover', 'focus']}
                            overlay={
                                <Tooltip
                                    id={`biosig-id`}
                                >{`Click here to re-enroll your BioSig ID if you're unable to proceed with the exam due to validation issues.`}</Tooltip>
                            }
                        >
                            <Button className='bp' onClick={this.validateBiosig}>
                                BioSig-ID
                            </Button>
                        </OverlayTrigger>
                    )}
                </span>
                <div className='content'>
                    <div className='content-container'>
                        <div className='content-container-wrapper'>
                            {course?.aboutExam && (
                                <div className='about-exam'>
                                    <h4>About the exam</h4>
                                    <div
                                        className='about-exam__content'
                                        dangerouslySetInnerHTML={{ __html: course.aboutExam }}
                                    />
                                </div>
                            )}
                            <div className='exam-rules'>
                                <h4>Exam rules</h4>
                                <div className='exam-rules__data'>
                                    <p>
                                        <span className='no-of-questions'>Number of questions: </span>
                                        {current ? current?.questions?.length : originalExamInfo.questionsLength}
                                    </p>
                                    <p>
                                        <span className='time-limit'>Time limit:&nbsp;</span>{' '}
                                        {current
                                            ? shortEnglishHumanizer(current?.timeLimit * 60000)
                                            : originalExamInfo.timeLimit}
                                    </p>
                                    <p>
                                        <span className='time-limit'>Pass mark:&nbsp;</span>
                                        {current ? `${current?.passPct}%` : originalExamInfo.passPct}
                                    </p>
                                </div>
                            </div>
                            <div className='attempt-display'>
                                <h4>Your exam attempts</h4>
                                <div className='attempt-display__data'>
                                    <p>
                                        <span className='attempt-limit'> Attempts allowed: </span>
                                        {this.getAttemptDisplayCount}
                                    </p>
                                    {this.displayWarningMessage()}
                                </div>
                            </div>
                            <div className='attempt-list-display'>
                                {lastAttempts.length > 0 ? (
                                    <PreviousAttempts
                                        examAttempts={lastAttempts}
                                        current={this.state.current}
                                        certificateData={this.state.certificateData}
                                        course={this.state.course}
                                        loadExamData={this.loadExamData}
                                    />
                                ) : (
                                    <em className='empty-attempt'>
                                        {
                                            "You haven't attempted the final exam yet. Any attempts you make will be shown here."
                                        }
                                    </em>
                                )}
                            </div>
                            {this.renderRepurchaseBlock()}
                        </div>
                    </div>
                </div>
                <div className='exam-footer'>
                    <div className='buttons-container'>
                        <Button className='bp exit-button' onClick={this.onExamExit}>
                            Back to course
                        </Button>
                        {current &&
                            this.getRemainingTime(current) !== 0 &&
                            current?.spentTime <= current?.timeLimit && (
                                <Button className='bp' onClick={this.enterInExam}>
                                    Resume Exam
                                </Button>
                            )}
                        {this.isStartExamBtnVisible() && this.getRemainingTime(current) <= 0 && (
                            <ConditionalWrapper
                                condition={!this.isExamAvailable.isAvailable || this.state.isUserRestricted}
                                wrapper={(children: any) => (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id={`tooltip-next-lesson`} className='abs-tooltip'>
                                                {this.state.isUserRestricted ? (
                                                    'Access to this exam has been temporarily blocked until maintenance is complete.'
                                                ) : (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.isExamAvailable.message,
                                                        }}
                                                    />
                                                )}
                                            </Tooltip>
                                        }
                                    >
                                        {children}
                                    </OverlayTrigger>
                                )}
                            >
                                <Button
                                    className={`bp ${
                                        !this.isExamAvailable.isAvailable || this.state.isUserRestricted
                                            ? 'button--disabled'
                                            : ''
                                    }`}
                                    onClick={
                                        this.isExamAvailable.isAvailable && !this.state.isUserRestricted
                                            ? this.startNewExam
                                            : undefined
                                    }
                                >
                                    Start Exam
                                </Button>
                            </ConditionalWrapper>
                        )}
                    </div>
                </div>

                {this.state.repurchasePackageId && (
                    <div className='repurchase-form'>
                        <ExtensionRepurchase
                            redirectUrl={this.props.location.pathname}
                            userPackageId={this.state.repurchasePackageId}
                            onClose={() => {
                                this.setState({ repurchasePackageId: '' });
                            }}
                            {...this.props}
                        />
                    </div>
                )}
            </div>
        );
    }
}
export default withRouter(ExamSummary);
