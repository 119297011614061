import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Api, EventBus } from 'src/helpers/new';
import Switcher from 'src/components/Switcher';
import { useSelector, useDispatch } from 'react-redux';
import { setLoggedIn, setSecretQuestions } from 'src/store/actions/actionList';
import { useParams } from 'react-router-dom';
import { RootState } from 'src/store';
import './SecretQuestion.scss';
import ConditionalWrapper from 'src/components/ConditionalWrapper';
import { FileUpload } from 'src/components/FileUpload';

interface IProps {
    isShowSecretQuestionModal: boolean;
    isSecretQuestionCheckPoint: boolean;
    updateLesson: (partialData: any) => void;
}

interface IRouteProps extends Record<string, string | undefined> {
    courseId: string;
    chapterId: string;
    lessonId: string;
}

interface ModalType {
    modalBody: string;
    modelClassName?: string;
    show: boolean;
    header?: string;
    buttonText: string;
    buttonClick: string;
    isButtonDisable?: boolean;
    backdrop?: boolean | 'static';
    modelSize?: any;
    isCloseButton: boolean;
    disableBtnTooltip?: string;
}

interface UserAnswer {
    _id: string;
    key: string;
    usersAnswer: string;
    question: string;
    value: number;
}

export const SecretQuestion = ({ isShowSecretQuestionModal, isSecretQuestionCheckPoint, updateLesson }: IProps) => {
    const dispatch = useDispatch();
    const secretQuestion = useSelector((state: any) => state.secretQuestion);
    const loggedIn = useSelector((state: RootState) => state.loggedIn);
    const params = useParams<IRouteProps>();
    useEffect(() => {
        if (isShowSecretQuestionModal) {
            secretQuestionAuthentication();
        } else {
            clearModalContent();
        }
    }, [isShowSecretQuestionModal, isSecretQuestionCheckPoint, secretQuestion]);

    const [modalContent, setModalContent] = useState<ModalType>({
        buttonClick: '',
        buttonText: '',
        show: false,
        header: '',
        backdrop: 'static',
        isButtonDisable: false,
        isCloseButton: true,
        modalBody: '',
    });
    const [modalData, setModalData] = useState<any>({});

    const handleAnswerChange = (e: any) => {
        setModalData((prevState: any) => {
            const newQuestions = [...(prevState?.questions ?? [])];
            newQuestions[(prevState?.currentSecretQuestionIndex ?? 1) - 1] = {
                ...newQuestions[(prevState?.currentSecretQuestionIndex ?? 1) - 1],
                usersAnswer: e?.target?.value,
            };

            const isButtonDisable = newQuestions.some((question) => !(question.usersAnswer ?? '').trim());
            setModalContent((prevModalContent) => ({
                ...prevModalContent,
                isButtonDisable,
                disableBtnTooltip: isButtonDisable
                    ? 'You cannot proceed because some questions still need to be answered. Use the arrows to select the questions and fill in all the answers.'
                    : '',
            }));
            return {
                ...prevState,
                questions: newQuestions,
            };
        });
    };

    const handleCurrentIndex = (index: number) => {
        setModalData((prevState: any) => {
            return {
                ...prevState,
                currentSecretQuestionIndex: index,
            };
        });
    };

    const updateSecretQuestionsSubmittedAt = (response: Date | null) => {
        dispatch(
            setLoggedIn({
                ...loggedIn,
                user: {
                    ...loggedIn.user,
                    secretQuestionsSubmittedAt: response,
                },
            }),
        );
    };

    const updateSecretQuestionsInRedux = (questions: UserAnswer[]) => {
        dispatch(setSecretQuestions(questions));
    };

    const handleSubmit = async () => {
        const questions = [...(modalData?.questions ?? [])];

        const payload = questions.map((que: UserAnswer) => {
            return {
                key: que.key,
                answer: que.usersAnswer,
            };
        });

        const { success, response, message } = await Api.call('POST', `/users/secretQuestions/answer`, payload);
        if (success) {
            updateSecretQuestionsSubmittedAt(response);
            const questionsWithoutAnswers = questions.map((q) => ({
                ...q,
                usersAnswer: '',
                _id: '',
            }));
            updateSecretQuestionsInRedux([...questionsWithoutAnswers]);

            EventBus.dispatch('toast', {
                type: 'success',
                message,
            });

            if (isSecretQuestionCheckPoint) {
                const modalContent = await getVerifySecretQuestionsModalContent();
                if (modalContent) {
                    setModalContent((prevState) => {
                        return {
                            ...prevState,
                            ...modalContent,
                        };
                    });
                }
            } else {
                clearModalContent();
            }
        }
    };

    const getSecretQuestionsContent = () => {
        const { questions = [], currentSecretQuestionIndex = 1 } = modalData;
        return [
            <p key='first-para'>{`This is your first time enrolling in a course!`}</p>,
            <p key='second-para'>
                {`We will use `}
                <b>{`11 secret questions. `}</b>
                {` to keep your account safe and verify your identity. Make sure your answers are unique and easy for you to remember.
                 During the course, you might be asked one of these questions, and you will need to give the correct answer
                  to prove it’s really you. `}
            </p>,
            <div key='input-from-user'>
                <Row>
                    <Col md={3} className='input-label'>
                        <b>Secret question {currentSecretQuestionIndex}</b>
                    </Col>
                    <Col>
                        <select
                            className='secret-questions-select'
                            value={currentSecretQuestionIndex}
                            onChange={({ target: { value } }) => {
                                handleCurrentIndex(parseInt(value));
                            }}
                        >
                            {questions.map((item: UserAnswer) => {
                                return (
                                    <option key={item.key} value={item.value}>
                                        {item.question}
                                    </option>
                                );
                            })}
                        </select>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col md={3} className='input-label'>
                        <label htmlFor='user-input'>
                            <b>Secret answer {currentSecretQuestionIndex}</b>
                        </label>
                    </Col>
                    <Col>
                        <input
                            type='text'
                            name='user-input'
                            value={questions[currentSecretQuestionIndex - 1]?.usersAnswer}
                            onChange={handleAnswerChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleCurrentIndex(Math.min(questions.length, currentSecretQuestionIndex + 1));
                                }
                            }}
                            maxLength={100}
                        ></input>
                    </Col>
                </Row>
            </div>,
            <Row key='switcher'>
                <Switcher
                    current={currentSecretQuestionIndex}
                    total={questions.length}
                    onPrev={(index: number) => {
                        handleCurrentIndex(index);
                    }}
                    onNext={(index: number) => {
                        handleCurrentIndex(index);
                    }}
                />
            </Row>,
        ];
    };

    const getSecretQuestionsModalContent = async () => {
        const questions = await getSecretQuestions();

        const modelData = {
            questions: [...questions],
            currentSecretQuestionIndex: 1,
        };

        if (modalData?.randomQuestion) {
            modelData.currentSecretQuestionIndex = modalData?.randomQuestion?.value;
        }

        if (modelData.questions?.length) {
            const isButtonDisable = modelData.questions.some((question) => !(question.usersAnswer ?? '').trim());
            return {
                modalContent: {
                    modelClassName: 'secret-questions collect-secret-questions-answers',
                    backdrop: false,
                    show: true,
                    header: `Secure your account with secret questions`,
                    modalBody: 'getSecretQuestionsContent',
                    buttonText: 'Confirm secret questions',
                    buttonClick: 'handleSubmit',
                    isButtonDisable: isButtonDisable,
                    modelSize: 'xl',
                    isCloseButton: false,
                    disableBtnTooltip: isButtonDisable
                        ? 'You cannot proceed because some questions still need to be answered. Use the arrows to select the questions and fill in all the answers.'
                        : '',
                },
                modalData: { ...modelData, randomQuestion: null },
            };
        } else {
            return null;
        }
    };

    const verifySecretQuestionSubmit = async () => {
        setModalContent((prevState) => {
            return {
                ...prevState,
                isButtonDisable: true,
            };
        });

        const randomQuestion = { ...modalData?.randomQuestion };

        const { courseId, lessonId } = params;

        const payload = {
            key: randomQuestion?.key,
            answer: randomQuestion?.usersAnswer?.trim(),
            userCourseId: courseId,
            userLessonId: lessonId,
        };

        const { success, response } = await Api.call('POST', `/users/secretQuestions/verifyAnswer`, payload);

        if (success && response) {
            if (response?.isCorrectAnswer) {
                updateLesson({ secretQuestionsValidated: true });
                clearModalContent();
            } else {
                handleRandomQuestionWrongAnswer();
            }
        }
    };

    const handleRandomQuestionAnswer = (e: any) => {
        setModalData((prevState: any) => {
            const randomQuestion = { ...prevState?.randomQuestion };
            randomQuestion.usersAnswer = e?.target?.value;
            randomQuestion.error = '';
            const isButtonDisable = !randomQuestion.usersAnswer.trim();

            setModalContent((prevModelContent) => {
                return {
                    ...prevModelContent,
                    isButtonDisable,
                };
            });

            return {
                ...prevState,
                randomQuestion: { ...randomQuestion },
            };
        });
    };

    const handleRandomQuestionWrongAnswer = () => {
        setModalData((prevState: any) => {
            const randomQuestion = { ...prevState.randomQuestion };
            randomQuestion.error = 'Provided answer does not match, try again!';

            randomQuestion.attemptCount = randomQuestion.attemptCount + 1;
            if (randomQuestion.attemptCount === 3 && randomQuestion.questionCount === 1) {
                randomQuestion.questionCount = randomQuestion.questionCount + 1;
            }
            if (randomQuestion.questionCount === 2 && randomQuestion.attemptCount === 3) {
                randomQuestion.error = 'Please submit a photo of your ID by clicking "Upload"';
                setModalContent((prevModelContent) => {
                    return {
                        ...prevModelContent,
                        buttonText: 'Upload',
                        buttonClick: 'openSubmitDocModal',
                        isButtonDisable: false,
                    };
                });
            }
            return {
                ...prevState,
                randomQuestion: randomQuestion,
            };
        });
    };

    const openSubmitDocModal = () => {
        setModalContent({
            modelClassName: 'secret-questions upload-doc',
            backdrop: false,
            show: true,
            header: `Submit your photo ID`,
            modalBody: 'getUploadDocModalBody',
            buttonText: 'Upload',
            buttonClick: 'uploadDocButtonHandlar',
            isButtonDisable: true,
            modelSize: 'lg',
            isCloseButton: false,
        });

        setModalData({});
    };

    const openDocUnderApprovalModal = (data: any) => {
        setModalContent({
            modelClassName: 'secret-questions doc-pending-approval',
            backdrop: false,
            show: true,
            header: ``,
            modalBody: 'getDocUnderVerificationModalContent',
            buttonText: '',
            buttonClick: '',
            isButtonDisable: false,
            modelSize: 'lg',
            isCloseButton: false,
        });

        const randomQuestion = {
            question: data.question,
            key: data.key,
            usersAnswer: data.usersAnswer,
        };

        setModalData((prevState: any) => {
            return {
                ...prevState,
                randomQuestion: { ...randomQuestion },
            };
        });
    };

    const getNewRandomQuestion = async () => {
        const response = await getUserSecretQuestions();

        const randomQuestion = {
            question: response.question,
            key: response.key,
            value: 1,
            usersAnswer: '',
            attemptCount: response.currentAttemptCount - 1,
            questionCount: response.currentAnswersLength,
        };

        setModalData((prevState: any) => {
            return {
                ...prevState,
                randomQuestion: { ...randomQuestion },
            };
        });
    };

    const callFunction = (title: string): any => {
        switch (title) {
            case 'getVerifySecretQuestionsContent': {
                return getVerifySecretQuestionsContent;
            }
            case 'getSecretQuestionsContent': {
                return getSecretQuestionsContent;
            }
            case 'handleSubmit': {
                return handleSubmit;
            }
            case 'verifySecretQuestionSubmit': {
                return verifySecretQuestionSubmit;
            }
            case 'uploadDocButtonHandlar': {
                return uploadDocButtonHandlar;
            }
            case 'getUploadDocModalBody': {
                return getUploadDocModalBody;
            }
            case 'openSubmitDocModal': {
                return openSubmitDocModal;
            }
            case 'getDocUnderVerificationModalContent': {
                return getDocUnderVerificationModalContent;
            }
            default:
                return () => {
                    return title;
                };
        }
    };

    const getVerifySecretQuestionsContent = () => {
        const question = { ...modalData.randomQuestion };

        return [
            <p key='first-para'>{`To confirm your identity, please answer one of the 11 secret questions you set up when you started the course.`}</p>,
            <Row key='question-row' className='random-question-row'>
                <Col>
                    <b>{question?.question}</b>
                </Col>
                {question.attemptCount === 3 && question.questionCount < 2 && (
                    <Col className='try-another-secret-question-btn-wrapper-col'>
                        <button onClick={getNewRandomQuestion} className={`link-btn try-another-secret-question-btn`}>
                            Try another question
                        </button>
                    </Col>
                )}
            </Row>,
            <div key='input-from-user'>
                <Row>
                    <Col>
                        <input
                            type='text'
                            name='user-input'
                            value={question?.usersAnswer}
                            onChange={handleRandomQuestionAnswer}
                            maxLength={100}
                            disabled={question.attemptCount === 3}
                        ></input>
                        {question?.error && (
                            <small className={'mt-2 form-builder__error-color'}>{question?.error}</small>
                        )}
                    </Col>
                </Row>
            </div>,
            <div key='attempt_count' className='wrong-attempts'>{`${question.attemptCount}  / 3 wrong attempts`}</div>,
        ];
    };

    const getDocUnderVerificationModalContent = () => {
        const question = { ...modalData.randomQuestion };

        return [
            <Row key='question-row' className='random-question-row'>
                <Col>
                    <b>{question?.question}</b>
                </Col>
            </Row>,
            <div key='input-from-user'>
                <Row>
                    <Col>
                        <input type='text' name='user-input' value={question?.usersAnswer} disabled={true}></input>
                        <small
                            className={'mt-2 form-builder__error-color'}
                        >{`To move on in the course, please wait for our team member to reach out to you with the correct answer to your question`}</small>
                    </Col>
                </Row>
            </div>,
        ];
    };

    const getUserSecretQuestions = async () => {
        const { response } = await Api.call('GET', `/users/secretQuestions/user/${loggedIn.user?._id}`);
        return response;
    };

    const getSecretQuestions = async () => {
        let questions: UserAnswer[] = [...(secretQuestion?.questions ?? [])];

        if (!questions.length) {
            const { success, response } = await Api.call('GET', `/users/secretQuestions/all`);
            if (success && response) {
                questions = response;
                updateSecretQuestionsInRedux(response);
            }
        }

        return questions;
    };

    const handleFileChange = (type: any, url: any, File: any) => {
        if (url) {
            setModalContent((prevModelContent) => {
                return {
                    ...prevModelContent,
                    isButtonDisable: false,
                };
            });

            setModalData((prevModalData: any) => {
                return {
                    ...prevModalData,
                    url: url,
                    docFile: File,
                };
            });
        } else {
            setModalContent((prevModelContent) => {
                return {
                    ...prevModelContent,
                    isButtonDisable: true,
                };
            });
        }
    };

    const getUploadDocModalBody = () => {
        return [
            <p key='second-para'>
                {`You answered two questions wrong in a row. To proceed, please submit a photo of your ID. This can be your driver’s license or residential card. Ensure that the image clearly shows your name, address, and date of birth.`}
            </p>,
            <div key='submit-doc'>
                <FileUpload
                    id={'file'}
                    name={'file'}
                    label={'Submit Photo ID'}
                    handleFileChange={handleFileChange}
                    type={'image'}
                    required={true}
                    description={' '}
                    validationError={``}
                    uploadedFileName={``}
                    maxSize={5}
                    restrictMaxSize={true}
                />
            </div>,
        ];
    };

    const uploadDocButtonHandlar = async () => {
        let payload = {};

        setModalContent((prevModalCOntent) => {
            return {
                ...prevModalCOntent,
                buttonText: 'Uploading..',
                isButtonDisable: true,
            };
        });

        if (modalData.docFile) {
            const imagePostData = new FormData();
            imagePostData.append('file', modalData.docFile);
            const { success, response } = await Api.call('POST', '/files', imagePostData);
            if (success) {
                const { courseId, lessonId } = params;

                payload = {
                    fileId: response.fileId,
                    userCourseId: courseId,
                    userLessonId: lessonId,
                };

                const { success: success2, response: response2 } = await Api.call(
                    'POST',
                    `/users/secretQuestions/submit-photo-id`,
                    payload,
                );

                if (success2) {
                    EventBus.dispatch('action-confirmation-popup', {
                        className: `secret-questions thank-you`,
                        title: 'Thank you !!',
                        body: `Thank you for submitting your ID. Our team will verify your identity and provide you with instructions on how to move on in the course.`,
                    });
                    openDocUnderApprovalModal(response2);
                }
            }
        }
    };

    const getVerifySecretQuestionsModalContent = async () => {
        const response = await getUserSecretQuestions();

        if (response?.currentDocumentStatus === 'Require') {
            openSubmitDocModal();
            return null;
        } else if (response.currentDocumentStatus === 'PendingApproval') {
            openDocUnderApprovalModal(response);
            return null;
        } else {
            const modelData = {
                randomQuestion: {
                    question: response.question,
                    key: response.key,
                    value: 1,
                    usersAnswer: '',
                    attemptCount: response.currentAttemptCount - 1,
                    questionCount: response.currentAnswersLength,
                },
            };

            if (modelData?.randomQuestion.question) {
                return {
                    modalContent: {
                        modelClassName: 'secret-questions verify-secret-questions-answer',
                        backdrop: false,
                        show: true,
                        header: `Answer a secret question to continue`,
                        modalBody: 'getVerifySecretQuestionsContent',
                        buttonText: 'Submit',
                        buttonClick: 'verifySecretQuestionSubmit',
                        isButtonDisable: true,
                        modelSize: 'lg',
                        isCloseButton: false,
                    },
                    modalData: modelData,
                };
            } else {
                return null;
            }
        }
    };

    const secretQuestionAuthentication = async () => {
        let data: { modalContent: ModalType; modalData: any } | null = null;
        if (!loggedIn.user?.secretQuestionsSubmittedAt) {
            data = await getSecretQuestionsModalContent();
        } else if (isSecretQuestionCheckPoint) {
            data = await getVerifySecretQuestionsModalContent();
        }

        if (data) {
            setModalData((prevState: any) => {
                return {
                    ...prevState,
                    ...data?.modalData,
                };
            });
            setModalContent((prevState) => {
                return { ...prevState, ...data?.modalContent };
            });
        }

        return modalContent;
    };

    const clearModalContent = () => {
        setModalContent({
            show: false,
            header: '',
            modalBody: '',
            buttonText: '',
            buttonClick: '',
            modelSize: undefined,
            modelClassName: '',
            isButtonDisable: false,
            backdrop: 'static',
            isCloseButton: true,
        });
        setModalData({});
    };

    const getModalButton = () => {
        if (!modalContent.buttonText) {
            return null;
        }
        return (
            <Button
                variant='primary'
                onClick={modalContent?.isButtonDisable ? () => {} : callFunction(modalContent.buttonClick)}
                disabled={modalContent?.isButtonDisable ?? false}
            >
                {modalContent.buttonText}
            </Button>
        );
    };

    return (
        <div>
            <Modal
                className={`secret-question-modal ${!modalContent?.backdrop ? 'modal-backdrop' : ''} ${
                    modalContent.modelClassName ?? ''
                }`}
                show={modalContent.show}
                onHide={clearModalContent}
                backdrop={modalContent?.backdrop ?? 'static'}
                size={['lg', 'sm', 'xl'].includes(modalContent?.modelSize) ? modalContent?.modelSize : undefined}
            >
                {modalContent.header && (
                    <Modal.Header closeButton={modalContent.isCloseButton}>
                        <h4>{modalContent.header}</h4>
                    </Modal.Header>
                )}
                <Modal.Body>{callFunction(modalContent.modalBody)()}</Modal.Body>
                {modalContent.buttonText && (
                    <Modal.Footer>
                        {modalContent.disableBtnTooltip ? (
                            <ConditionalWrapper
                                condition={modalContent?.isButtonDisable && modalContent.disableBtnTooltip}
                                wrapper={(children: any) => (
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id={`cancel-tooltip-text`} className='abs-tooltip'>
                                                {modalContent.disableBtnTooltip}
                                            </Tooltip>
                                        }
                                    >
                                        {children}
                                    </OverlayTrigger>
                                )}
                            >
                                <div>{getModalButton()}</div>
                            </ConditionalWrapper>
                        ) : (
                            getModalButton()
                        )}
                    </Modal.Footer>
                )}
            </Modal>
        </div>
    );
};
